export default {
  data() {
    return {
      web3: null,
      // 表單驗證
      dataRules: (v) => !!v || 'Required',
      listRules: (v) => v.length !== 0 || 'Required',
      TokenAmountRules: [
        (v) => !!v || 'Required',
        (v) => /^[0-9]/.test(v) || 'Number Only',
        (v) => v > 0 || 'Cannot Be Negative',
      ],
      accountRules: [
        (v) => !!v || 'Required',
        (v) => /0x[\w]{40}/.test(v) || 'Please Enter Correct Address',
        (v) => v.length === 42 || 'Please Enter Correct Address',
      ],
      voteRules: [
        (v) => v <= 300 || 'No More Than 300',
        (v) => v >= 10 || 'Must Higher Than 10',
      ],
      TokenAmountRulesNoRequired: [
        (v) => /^[0-9]/.test(v) || 'Number Only',
        (v) => v > 0 || 'Cannot Be Negative',
      ],
      rateRules: [
        (v) => v >= 0.1 || 'Must Higher Than 0.1%',
        (v) => v <= 100 || 'No More Than 100%',
      ],
    };
  },
  methods: {
    timestampToDate(timestamp){
      let time = new Date(timestamp)
      let year = time.getFullYear()
      let month = time.getMonth() + 1
      let date = time.getDate()
      return `${year}-${month<10?`0${month}`:month}-${date<10?`0${date}`:date}`
    },
    timestampToTime(timestamp){
      let time = new Date(timestamp)
      let year = time.getFullYear()
      let month = time.getMonth() + 1
      let date = time.getDate()
      let hour = time.getHours()
      let min = time.getMinutes()
      let sec = time.getSeconds()

      return `${year}-${month<10?`0${month}`:month}-${date<10?`0${date}`:date} ${hour<10?`0${hour}`:hour}:${min<10?`0${min}`:min}:${sec<10?`0${sec}`:sec}`
    },
    round(num, roundTo){
      if (num){
        let result
        if (roundTo !== undefined){
          result = Number.isInteger(num) ? num : (num).toFixed(roundTo)
        }else{
          result = Number.isInteger(num) ? num : (num).toFixed(2)
        }
        return result
      }else{
        return null
      }
    },
    // 取得欲抵押的 EGT / Eth 市值
    async getValue(token, amount){
      try{
        let tokenType = token==='egt' || token==='eth' ? 'ethereum' : 
                        token==='btc'                  ? 'bitcoin'  : token
        let result = await this.$store.dispatch('getTokenPrice', {
          token: tokenType,
          currency: 'usd'
        })
        if (result){
          // console.log('result', result)
          if (token === 'egt'){
            return parseFloat((parseFloat(amount / 1200) * result[tokenType].usd).toFixed(2))
          }else{
            return parseFloat((parseFloat(amount) * result[tokenType].usd).toFixed(2))
          }
        }else{
          this.$toasted.error(this.$t('cannotGetMarketValue'))
          return null
        }
      }catch (error){
        console.log('error', error)
        this.$toasted.error(this.$t('cannotGetMarketValue'))
        return null
      }
    },
    // 取得抵押率(貸款成數)
    async getRate(amount, value){
      return value ? (amount / value * 100 ).toFixed(2) || null : null
    },
  },
};
